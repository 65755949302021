<template>
  <div class="guidance">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Guidance</h1>
    </div>
    <!-- / Page Header -->

    <!-- Guidance packs wrapper -->
    <div class="guidance-packs-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoadingCombined">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No guidance packs -->
      <a-alert
        v-if="!isLoadingCombined && guidancePacks.length == 0"
        type="info"
        message="No guidance packs to show"
      />
      <!-- / No guidance packs -->

      <!-- Loaded -->
      <div
        class="guidance-packs-list"
        v-if="!isLoadingCombined && guidancePacks.length"
      >
        <a-row :gutter="20">
          <a-col
            :span="colspan"
            v-for="guidancePack in guidancePacksSorted"
            :key="guidancePack.id"
          >
            <guidance-pack-card
              @selected="() => navigateToGuidancePack(guidancePack)"
              :guidance-pack="guidancePack"
            ></guidance-pack-card>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- Guidance packs wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GuidancePackCard from "./Guidance/GuidancePackCard.vue";
import RequiresNonHiddenPresences from "../mixins/RequiresNonHiddenPresences";
// const _ = require("lodash");
export default {
  components: { GuidancePackCard },
  mixins: [RequiresNonHiddenPresences],
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  created() {
    if (!this.guidanceModeEnabled) {
      this.$message.error("You do not have permission to access guidance");
      this.$router.push("/");
    } else {
      this.loadGuidancePacks();
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    ...mapGetters("missionControl", {
      isLoadingMissionControl: "isLoading",
      guidanceModeEnabled: "guidanceModeEnabled",
    }),

    ...mapGetters("guidance", {
      guidancePacks: "guidancePacks",
      guidancePacksSorted: "guidancePacksSorted",
      isLoading: "isLoading",
    }),

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },

    colspan() {
      if (this.windowWidth > 1200) {
        return 8;
      }
      if (this.windowWidth > 800) {
        return 12;
      }
      if (this.windowWidth > 500) {
        return 24;
      }
      return 24;
    },
  },
  methods: {
    ...mapActions("guidance", {
      loadGuidancePacks: "loadGuidancePacks",
      loadLastViews: "loadLastViews",
    }),

    navigateToGuidancePack(guidancePack) {
      this.$router.push(
        "/guidance/" +
          guidancePack["$v"]["tenantId"] +
          "/" +
          guidancePack["$v"]["id"]
      );
    },
  },
};
</script>

<style scoped lang="scss">
.guidance-packs-list-wrapper {
  padding-bottom: 30px;
}

.guidance-pack-card-wrapper {
  margin-bottom: 20px;
}
</style>